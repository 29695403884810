.qa-view {
  padding: $spacing;
  height: calc(100vh - 100px);

  &-content {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;

    .qa-data-creator {
      grid-row: 1;
      grid-column: 1 / 4;
    }
    .qa-data-injector {
      grid-row: 1;
      grid-column: 4 / 7;
    }

    .qa-data-injector-card {
      .card-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
