@import '~@beewise/theme/build/variables';

.sequences-modal {
  .ag-theme-material .no-border.ag-cell:focus {
    border: none;
    outline: none;
  }
  .ag-grid {
    height: calc(100vh - 250px)
  }

  .sequence-modal-header {
    display: flex;
    align-items: center;
    .icon-sync {
      margin-left: $spacing;
    }

    .icon-sync-loading {
      background-color: $gray;
      color: $white;
      cursor: default;
    }
  }

  .icon-copy {
    position: absolute;
    right: 0;
    padding: 0 5px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
    cursor: pointer;
    background-color: $white;
  }
}

