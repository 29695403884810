.errors {
    padding: $spacing;
    height: calc(100vh - 100px);
    position: relative;

    .ag-theme-material .no-border.ag-cell:focus {
        border: none;
        outline: none;
    }

    .icon-sync {
        position: absolute;
        top: 38px;
        transform: translateY(-50%);
        left: 175px;
    }

    &-pickers {
        position: absolute;
        top: 22px;
        left: 218px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .react-datepicker__input-container {
        height: 32px;
        width: auto;

        input {
            height: 100%;
            border: 1px solid $gray-04;
            min-width: 300px;
            box-sizing: border-box;
        }
    }
    .alert-name, .encoder-renderer {
        line-height: 16px;
    }
}
